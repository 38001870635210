import { Children, useEffect, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { windowCheck } from 'utils/helpers';

type TabsProps = {
  activeClassName?: string,
  anchorLinks?: boolean,
  children?: any | undefined,
  className?: string,
  classNames?: any,
  label: string[],
  startTab?: string
 }

const Tabs = ({ label, className, activeClassName, children, startTab, anchorLinks }:TabsProps) => {
  const currentHash = windowCheck && location.hash.substring(1);
  const hashLabels = label.map(label => {
    return label.replace(' ', '-').toLowerCase();
  });
  const start = startTab ? label[parseInt(startTab)] : '';
  const [activeTab, setActiveTab] = useState(start);
  useEffect(() => {
    if (anchorLinks && currentHash && hashLabels.indexOf(currentHash) != -1) {
      setActiveTab(label[hashLabels.indexOf(currentHash)]);
    } else {
      setActiveTab(start ? start : label[0]);
    }
  }, [currentHash]);

  const breakpoints = useBreakpoint();

  function changeTab(value) {
    if (anchorLinks) {
      history.pushState({}, '', `#${hashLabels[label.indexOf(value)]}`);
    }
    setActiveTab(value);
  }

  // Mobile renders dropdown
  const Select: any = () => (
    <div className="select is-rounded">
      <select value={ activeTab } onChange={ e => changeTab(e.target.value) }>
        {label.map((label, index) => (
          <option
            key={ index }
            id={ hashLabels[index] }
            title={ label }
            className={ `wiw-tabs-link ${label === activeTab ? ' active' : ''}` }
          >
            {label}
          </option>
        ))}
      </select>
    </div>
  );

  // Desktop renders buttons
  const Button: any = () =>
    label.map((label, index) => (
      <button
        key={ `button${index}` }
        id={ hashLabels[index] }
        role="button"
        title={ label }
        onClick={ () => changeTab(label) }
        className={ `button wiw-tabs-link ${
          label === activeTab ? ' active' : ''
        }` }
      >
        {label}
      </button>
    ));

  return (
    <div
      className={ `wiw-tabs ${
        typeof className === 'undefined' ? '' : className
      }` }
    >
      <nav className="wiw-tabs-nav" data-section="tabs">
        {breakpoints.sm ? <Select /> : <Button />}
      </nav>

      <div className="wiw-tabs-content">
        {Children.map(children, (_, index) => (
          <div
            key={ `unique${index}` }
            className={ `tab-content-${index} ${
              typeof activeClassName === 'undefined' ? '' : activeClassName
            }${label[index] === activeTab ? ' active' : ''}` }
          >
            {label[index] === activeTab ? children[index] : null}
          </div>
        ))}
      </div>


    </div>
  );
};

export default Tabs;