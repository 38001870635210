import ACFImage from 'types/ACFImage';

import Link from 'components/link/link';

import './styles/news-room.scss';

type HeroNewsRoomProps = {
  buttons: Buttons[];
  sub_title: string;
  title: string;
};

type Buttons = {
  copy: string;
  icon: ACFImage;
  link_type: Link;
};

type Link = {
  asset: ACFImage;
  email: string;
  url: string;
};

export const HeroNewsRoom = ({ title, sub_title, buttons }:HeroNewsRoomProps) => (
  <section className="news-room-hero-section section hero">
    <div className="container">
      <div className="columns">
        <div className="column">
          <h1 className="title">{title}</h1>
          <h2>{sub_title}</h2>
        </div>
        <div className="column">
          <div className="buttons columns">
            {buttons.map((button, index) => {
              function createUrls() {
                if (button.link_type.email !== '')
                  return `mailto:${button.link_type.email}`;
                if (button.link_type.url !== '') return button.link_type.url;

                return button.link_type.asset.source_url;
              }
              return (
                <Link
                  key={ index++ }
                  className="button button-link is-full-width-mobile"
                  to={ createUrls() }
                >
                  <img
                    className="button-icon"
                    src={ button.icon.source_url }
                    alt="When I Work Icon"
                  />
                  <p className="button-text">{button.copy}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </section>
);