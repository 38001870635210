import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Yoast from 'components/globals/yoast';
import { HeroNewsRoom } from 'components/hero/news-room';
import PostCard from 'components/posts/post-card';
import Tabs from 'components/tabs/tabs';

import './styles/news-room.scss';
import '../components/posts/styles/post-cards.scss';

export default function NewsRoom() {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {slug: {eq: "newsroom"}}) {
      edges {
        node {
          wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          acf {
            news_room_hero {
              title
              sub_title
              buttons {
                copy
                link_type {
                  email
                  url
                  asset {
                    source_url
                  }
                }
                icon {
                  source_url
                }
              }
            }
          }
        }
      }
    }
    company: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "press-room"}}}}
    ) {
      edges {
        node {
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    product: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {in: ["product-blog", "product-updates"]}}}}
    ) {
      edges {
        node {
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    leadership: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "future-of-work"}}}}
    ) {
      edges {
        node {
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    press: wordpressAcfOptions {
      options {
        press_category_custom_posts {
          edges {
            node {
              slug
              title
              date
              featured_media {
                source_url
              }
            }
          }
        }
      }
    }
  }
  
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.news_room_hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const company = data.company;
  const product = data.product;
  const leadership = data.leadership;
  const press = data.press.options.press_category_custom_posts;

  // setup named tab structure
  const tabs = [
    {
      resource: company,
      label: 'Company',
    },
    {
      resource: product,
      label: 'Product',
    },
    {
      resource: leadership,
      label: 'Thought Leadership',
    },
    {
      resource: press,
      label: 'Press',
    },
  ];

  const label = tabs.map(tab => tab.label);

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="news-room-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroNewsRoom { ...hero } />
        <section className="section tabs-section">
          <div className="container">
            <Tabs
              label={ label }
              activeClassName="posts-section columns"
              startTab="3"
            >
              {tabs.map(tab =>
                tab.resource.edges.map(({ node }) => {
                  // Regex pattern which identifies external links
                  const externalLink = new RegExp(/http/);
                  const externalLinkLogic = externalLink.test(node.slug)
                    ? node.slug
                    : `/blog/${node.slug}`;
                  const source_url = node.featured_media === null
                    ? 'https://marketing-assets.wheniwork-production.com/2021/12/11223232/generic_blog_image_post_header.jpg'
                    : node.featured_media.source_url;

                  const card = {
                    slug: externalLinkLogic,
                    source_url: source_url,
                    name: node.name,
                    title: node.title,
                    date: node.date,
                  };

                  return <PostCard
                    key={ card.slug }
                    card={ card }
                  />;
                })
              )}
            </Tabs>
          </div>
        </section>
      </div>
    </Layout>
  );
}
